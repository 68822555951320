<!--事件汇总表格  -->
<template>
  <div class="">
    <!-- table模块 -->
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :showToolBar="false"
      @action-column-click="actionColumnClick"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
    </ax-table>
    <!-- 详情弹窗 -->
    <watch ref="watch"></watch>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "姓名/身份证号/联系电话" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "select",
    model: "cellName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "事件类别",
    type: "select",
    model: "eventCategory",
    options: {
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "事件来源",
    type: "select",
    model: "eventSource",
    options: {},
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "事件状态",
    type: "select",
    model: "eventStatus",
    options: {},
    formItem: {},
    col: { span: 6 },
  },
];

import api from "./api.js";
import watch from '../watch/watch.vue';

export default {
  components: { watch },
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      activeTab: ["1"],
      visible: false,
      activeTabIndex: ["1"],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "事件编号",
            dataIndex: "residentName",
            ellipsis: false,
            width: 120,
          },
          {
            title: "所属网格",
            dataIndex: "gender",
            ellipsis: false,
            width: 80,
          },
          {
            title: "事件名称",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 190,
          },
          {
            title: "事件状态",
            dataIndex: "age",
            ellipsis: false,
            width: 70,
          },
          {
            title: "事件类别",
            dataIndex: "nation_dictText",
            ellipsis: true,
            width: 100,
          },

          {
            title: "事件来源",
            dataIndex: "educationalLevel_dictText",
            ellipsis: false,
            width: 120,
          },
          {
            title: "超时情况",
            dataIndex: "politicalStatus_dictText",
            ellipsis: true,
            width: 150,
          },

          {
            title: "上报人",
            dataIndex: "maritalStatus_dictText",
            ellipsis: false,
            width: 100,
          },
          {
            title: "联系方式",
            dataIndex: "job_dictText",
            ellipsis: false,
            width: 120,
          },
          {
            title: "登记时间",
            dataIndex: "birthDate",
            ellipsis: false,
            width: 120,
          },
          {
            title: "处理期限",
            dataIndex: "currentAreaCode",
            ellipsis: false,
            width: 120,
          },
        ],
        false,
        {
          // fixed: "right",
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true, //是否要弹窗
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: { type: "0" },
      currentAreaCode: "",
      currentArea: "",
      ageStart: "",
      ageEnd: "",
      currentAreaCode1: [],
      cascaderVisible: true, // 控制级联选择器的显示/隐藏
      type: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    activeTab(newValue) {
      console.log(newValue);
      if (newValue[0] === "1") {
        this.dataSourceApis = api.list;
        this.type = "0";
        this.dataSourceParams = { type: "0" };
      } else if (newValue[0] === "2") {
        this.type = "1";
        this.dataSourceApis = api.list;
        this.dataSourceParams = { type: "1" };
      }
      // 在这里更新了数据源后，如果您的表格组件正确地绑定了 dataSourceApi，它应该会自动重新渲染以显示新的数据。
    },
  },
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "watch":
          // record.title = "查看居民信息";
          this.$refs.watch.openModal({record,title:'事件详情'});
          break;
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    // 获取字典
    async getDictionsary() {
      // 事件类别
      await api.dictData({ dicKind: "event_category" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "eventCategory",
          {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              mode: "multiple",
            },
          }
        );
      });
      // 事件状态
      await api.dictData({ dicKind: "event_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "eventStatus",
          {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              mode: "multiple",
            },
          }
        );
      });
      // 事件来源
      await api.dictData({ dicKind: "event_source" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "eventSource",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.getDictionsary();
    // this.getRegionInfo();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #f6ffed;
  color: #389e0d;
  border-radius: 4px;
  border: #f6ffed;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>